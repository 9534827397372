<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Gspvpcheck:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Gspvpcheck:Save'">保存</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-form ref="basicForm" :model="dataSource" :rules="basicValidate">
                <el-tabs type="border-card" v-model.trim="activeName">
                    <el-tab-pane label="" name="tabbasic">
                        <el-collapse v-model.trim="collapseItem">
                            <el-collapse-item title="基本信息" name="basicinfo">
                                <el-form-item v-if="!config.isAdd">
                                    <el-col :span="3" class="form-title">单据号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.GspRecordNo" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="CustomerCode">
                                            <LookPopup v-model="dataSource.CustomerCode"
                                                dataTitle="货主选择"
                                                dataUrl='omsapi/customerinfo/getlist'
                                                syncField='CustomerCode'
                                                @syncFieldEvent="syncCustomerEvent"
                                                rules="basicValidate.CustomerCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">货主名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.CustomerName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客商编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="VendorCode">
                                            <LookPopup v-model="dataSource.VendorCode"
                                                dataTitle="客商选择"
                                                dataUrl='omsapi/customervendor/getlist'
                                                syncField='VendorCode'
                                                @syncFieldEvent="syncVendorEvent"
                                                rules="basicValidate.VendorCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">客商名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.VendorName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNo">
                                            <LookPopup v-model="dataSource.ProductNo"
                                                dataTitle="商品选择"
                                                dataUrl='omsapi/product/getproductlist'
                                                syncField='ProductNo'
                                                @syncFieldEvent="syncProductEvent"
                                                rules="basicValidate.ProductNo"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.ProductNo" 
                                                suffix-icon="el-icon-search" readonly
                                                ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">商品中文名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductName" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">规格：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductSpec"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成厂家：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductCompany"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>批号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductBatchCode">
                                            <el-input v-model.trim="dataSource.ProductBatchCode"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNum">
                                            <el-input v-model.trim="dataSource.ProductNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">包装单位：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductUnit"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">包装数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductUnitNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="抽检信息" name="basicinfo1">
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>抽检数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="SpotCheckNum">
                                            <el-input v-model.trim="dataSource.SpotCheckNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>检查结果：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="SpotCheckResult">
                                            <DictionarySelect v-model="dataSource.SpotCheckResult" datType='Int' dicTypeCode="GspCheckResultCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>抽检可见异常数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="SpotCheckVpExcNum">
                                            <el-input v-model.trim="dataSource.SpotCheckVpExcNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">抽检其他可见异常数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.SpotCheckOthenExcNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="初检信息" name="basicinfo2">
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>初检不合格率：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="InitialCheckFailureRate">
                                            <el-input v-model.trim="dataSource.InitialCheckFailureRate">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>初检结果：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="InitialCheckResult">
                                            <DictionarySelect v-model="dataSource.InitialCheckResult" datType='Int' dicTypeCode="GspCheckResultCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">质检员：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="InitialCheckInspector">
                                            <el-input v-model.trim="dataSource.InitialCheckInspector"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">初检意见：</el-col>
                                    <el-col :span="21">
                                        <el-form-item  prop="InitialCheckContent">
                                            <el-input v-model.trim="dataSource.InitialCheckContent" type="textarea" rows="3"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="复检信息" name="basicinfo3">
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>复检不合格率：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="RecheckFailureRate">
                                            <el-input v-model.trim="dataSource.RecheckFailureRate">
                                                <template slot="append">%</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>复检结果：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="RecheckResult">
                                            <DictionarySelect v-model="dataSource.RecheckResult" datType='Int' dicTypeCode="GspCheckResultCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">复检员：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="RecheckInspector">
                                            <el-input v-model.trim="dataSource.RecheckInspector"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">复检意见：</el-col>
                                    <el-col :span="21">
                                        <el-form-item  prop="RecheckContent">
                                            <el-input v-model.trim="dataSource.RecheckContent" type="textarea" rows="3"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">制单人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.CreatorName"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">制单时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.CreateTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </el-form> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1','basicinfo2','basicinfo3'],
                basicValidate: {
                    CustomerCode:[{ required: true, message: '货主不能为空', trigger: 'blur' }],
                    VendorCode:[{ required: true, message: '客商不能为空', trigger: 'blur' }],
                    ProductNo:[{ required: true, message: '商品不能为空', trigger: 'blur' }],
                    ProductBatchCode:[{ required: true, message: '批号不能为空', trigger: 'blur' }],
                    ProductNum:[{ required: true, message: '数量不能为空', trigger: 'blur' }],
                    SpotCheckNum:[{ required: true, message: '抽检数量不能为空', trigger: 'blur' }],
                    SpotCheckResult:[{ required: true, message: '抽检结果不能为空', trigger: 'change' }],
                    InitialCheckFailureRate:[{ required: true, message: '初检不合格率不能为空', trigger: 'blur' }],
                    InitialCheckResult:[{ required: true, message: '抽检结果不能为空', trigger: 'change' }],
                    RecheckFailureRate:[{ required: true, message: '复检不合格率不能为空', trigger: 'blur' }],
                    RecheckResult:[{ required: true, message: '复检结果不能为空', trigger: 'change' }]
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
            this.initialBaseList();
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/gspvpcheck/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            initialBaseList(){
                var _this = this;
                _this.$ajax.send("omsapi/base/getlistbyenumname", "get", {name:'GspProcessStatusCode'}, (data) => {
                    if(data.IsSuccess) 
                        _this.processStatusList=data.Result;
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/gspvpcheck/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.config.isAdd = false;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
        },
        components: {
        }
    }
</script>